import {
    Box,
    IconButton,
    Typography,
    useTheme,
    Popover,
    Button,
    Avatar,
    Divider,
  } from "@mui/material";
  import { useContext, useState, useEffect } from "react";
  import { ColorModeContext, tokens } from "../../theme";
  import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
  import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
  import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
  import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
  import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
  import ExitToAppIcon from "@mui/icons-material/ExitToApp";
  import { useNavigate } from "react-router-dom";
  
  import { Card, Chip } from "@mui/material";
  import { SyncRounded } from "@mui/icons-material";
  import axios from "axios";
  
  const MemTopbar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const navigate = useNavigate();
  
    const [userData, setUserData] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
   
    useEffect(() => {
      fetchUserData();
    }, []);
  
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleLogout = () => {
      localStorage.removeItem("token");
      localStorage.removeItem("cmpid");
      localStorage.removeItem("cmpname");
      navigate("/");
    };
  
    const handlresetPwd = () => {
      navigate("/forgot-password");
    };
  
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
   
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/profile/info",
          {},
          {
            headers: { Authorization: token },
          }
        );
  
        setUserData(response.data.user[0]);
        const usersData = response.data.user[0];
        localStorage.setItem("Role", usersData.role);
        const userName = usersData
          ? usersData.role === "admin"
            ? `${usersData.admin_FirstName} ${usersData.admin_LastName}`
            : usersData.user_Name
          : "User Name";
        localStorage.setItem("username", userName);
      } catch (err) {
        console.log("Error fetching user data");
        
      }
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? "user-popover" : undefined;
  
    const avatarLetter = userData
      ? userData.role === "admin"
        ? userData.admin_FirstName.charAt(0).toUpperCase()
        : userData.user_Name.charAt(0).toUpperCase()
      : "U";
    const userName = userData
      ? userData.role === "admin"
        ? `${userData.admin_FirstName} ${userData.admin_LastName}`
        : userData.user_Name
      : "User Name";
    const userEmail = userData
      ? userData.role === "admin"
        ? userData.admin_Email
        : userData.user_Email
      : "user@example.com";
  
    return (
      <Box
        width="100vw"
        marginLeft={0}
        display="flex"
        justifyContent="space-between"
        p={0.7}
        backgroundColor={colors.primary[400]}
        boxShadow={"1px 0px 2px rgba(0, 0, 0, 0.5)"}
        position={"fixed"}
        top={0}
        zIndex={1000}
      >
        {/* Left Side: Logo and Text */}
        <Box display="flex" alignItems="center">
          <img
            src="../../assets/infinite.png"
            alt="Shraddha Infinite"
            style={{
              height: "50px",
              width: "80px",
            }}
          />
          <Box
            display="flex"
            flexDirection="column"
            ml={1}
            alignItems="center"
          >
            <Typography
              variant="h3"
              color="textPrimary"
              sx={{
                fontWeight: "bold",
                fontFamily:
                  '"system-ui", "-apple-system", "Segoe UI", "Roboto", "Arial", sans-serif',
              }}
            >
              Shraddha
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                fontSize: "1.3rem",
                marginTop: "2px",
                lineHeight: 1,
                color:"#B09246",// "#6870fa",
              }}
            >
              Infinite 
            </Typography>
          </Box>
        </Box>
  
        {/* ICONS */}
        <Box display="flex" alignItems="center" marginRight="50px">
          <Box ml={3}>
            <IconButton aria-label="Settings">
              <SettingsOutlinedIcon />
            </IconButton>
          </Box>
          <IconButton
            onClick={colorMode.toggleColorMode}
            aria-label="Toggle Theme"
          >
            {theme.palette.mode === "dark" ? (
              <LightModeOutlinedIcon />
            ) : (
              <DarkModeOutlinedIcon />
            )}
          </IconButton>
          <IconButton onClick={handleClick} aria-label="User Profile">
            <PersonOutlinedIcon />
          </IconButton>
        </Box>
  
        {/* User Popup */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            style: {
              backgroundColor: colors.primary[400],
              color: colors.primary[100],
              borderRadius: "10px",
            },
          }}
        >
          <Box padding={2} textAlign="center">
            {/* Avatar */}
            <Box
              alignItems="center"
              justifyContent="center"
              style={{ padding: "10px", height: "100px" }}
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <Avatar
                  style={{
                    backgroundColor: "#EFE17C",//"#f39c12",
                    alignItems: "center",
                    justifyContent: "center",
                    color:"#000000",
                  }}
                >
                  {avatarLetter}
                </Avatar>
              </Box>
              <Box padding="10px">
                <Typography variant="h3" style={{ margin: "0" }}>
                  {userName}
                </Typography>
                <Typography
                  variant="h4"
                  color="textSecondary"
                  style={{ margin: "0" }}
                >
                  {userEmail}
                </Typography>
              </Box>
            </Box>
  
            <Divider style={{ margin: "10px 0", marginTop: "30px" }} />
  
            {/* Buttons */}
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap="12px"
            >
              <Box display="flex" alignItems="center">
                <SettingsOutlinedIcon style={{ color: colors.primary[100] }} />
                <Button variant="text" color="inherit" onClick={handlresetPwd}>
                  Change Password
                </Button>
              </Box>
              <Box display="flex" alignItems="center" marginRight="60px">
                <ExitToAppIcon style={{ color: colors.primary[100] }} />
                <Button variant="text" color="inherit" onClick={handleLogout}>
                  Logout
                </Button>
              </Box>
            </Box>
  
            <Divider style={{ margin: "16px 0" }} />
  
            {/* Privacy and policy */}
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="body2" color="textSecondary">
                Privacy Policy | Security Statement
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                style={{ marginTop: "8px" }}
              >
                © 2024 - 1.0.0
              </Typography>
            </Box>
          </Box>
        </Popover>
      </Box>
    );
  };
  
  export default MemTopbar;
  