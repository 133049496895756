import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import SchoolIcon from "@mui/icons-material/School";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MenuOpen from "@mui/icons-material/MenuOpen";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import EventNoteIcon from "@mui/icons-material/EventNote";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const MemberSidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("ShraddhaEvents");
  // const token = localStorage.getItem("token");
  //const cmpid = localStorage.getItem("cmpid");

  const [isKnowledgeOpen, setisKnowledgeOpen] = useState(false);

  const handleKnowledgeToggle = () => {
    setisKnowledgeOpen(!isKnowledgeOpen); // Toggle the open state
  };

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
          boxShadow: "2px 0 2px -2px rgba(0, 0, 0, 0.5)",
          marginTop: "28px",
          position: isCollapsed ? "fixed" : "fixed",
          height: "100vh", //isCollapsed ? "calc(100vh - 104px)" : "calc(100vh - 104px)",
          overflowY: "auto",
          top: isCollapsed ? "35px" : "35px",
          left: 0,
          width: isCollapsed ? "81px" : "250px",
          zIndex: isCollapsed ? 999 : 999,
          transition: "all 0.3s ease",
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          color: `${colors.grey[400]}!important`,
        },
        "& .pro-inner-item:hover": {
          color: "#CBAD5B !important", //"#868dfb !important",
        },
        "& .pro-menu-item": {
          color: "#6870fa !important",
          borderRadius: "10px",
          fontSize: "10px",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
          backgroundColor: `${colors.blueAccent[900]}!important`,
          borderRadius: "10px",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/*LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : <MenuOpen />}
            style={{
              margin: 0,
              padding: 0,
              color: colors.grey[100],
            }}
          ></MenuItem>

          {/* Menu Items */}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handleKnowledgeToggle}
            >
              <SchoolIcon style={{ marginLeft: "25px", color: "#B09246" }} />

              {/* Add your icon here */}
              <Typography
                variant="h4"
                paddingLeft={isCollapsed ? "10%" : "0px"}
                color={colors.primary[100]}
                sx={{
                  m: "15px 0 5px 20px",
                  cursor: "pointer",
                }}
              >
                Knowlege
              </Typography>

              {isKnowledgeOpen ? ( // Show up arrow when expanded
                <ExpandLessIcon
                  style={{ marginLeft: "auto", color: colors.primary[100] }}
                />
              ) : (
                // Show down arrow when collapsed
                <ExpandMoreIcon
                  style={{ marginLeft: "auto", color: colors.primary[100] }}
                />
              )}
            </div>
            {isKnowledgeOpen && ( // Conditionally render items
              <>
                <div
                  style={{
                    marginLeft: "20px",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Item
                    title="Events"
                    to="/ShraddhaEvents"
                    icon={<EventNoteIcon style={{ color: "#B09246" }} />}
                    selected={selected}
                    setSelected={setSelected}
                  />

                  <Item
                    title="Training Videos"
                    to="/ShraddhaMemeberTV"
                    icon={<OndemandVideoIcon style={{ color: "#B09246" }} />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </div>
              </>
            )}
            <div
              style={{
                color: "#868dfb",
                alignItems: "left",
                justifyContent: "left",
                fontSize: "25px",
                fontWeight: "bolder",
              }}
            >
              <Item
                title="Support"
                to="/LearningAndEvents"
                icon={<SupportAgentIcon style={{ color: "#B09246" }} />}
                selected={selected}
                setSelected={setSelected}
                style={{ fontSize: "30px" }}
              />

              <Item
                title="Shraddha Infinite"
                to="/ShraddhaInfinite"//"/Dashboard" //
                icon={<AllInclusiveIcon style={{ color: "#B09246" }} />}
                selected={selected}
                setSelected={setSelected}
              />
            </div>
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default MemberSidebar;
