import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import Header from "../../components/Header";
import axios from "axios";

export default function ShraddhaEvents() {
  const token = localStorage.getItem("token");
  const [EventList, setEventList] = useState([]);
  
  const fetchData = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/display/eventList",
        {},
        {
          headers: { Authorization: token },
        }
      );
      setEventList(response.data.rows);
    } catch (error) {
      console.log("Error fetching data");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box sx={{ padding: "20px", marginTop: "55px" }}>
      <Header title="Events" subtitle="Events Calendar" />

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        {EventList.map((event) => (
          <Card key={event.event_Id} sx={{ width: 300, boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                {event.event_Name}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {event.event_SubName}
              </Typography>
              <Typography variant="body1">
                Date: {event.event_StartDay}
              </Typography>
              <Typography variant="body1">
                Time: {event.event_StartTime} - {event.event_EndTime}
              </Typography>
              <Typography variant="body2">
                Location: {event.event_Location}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#B09246",
                  ":hover": { backgroundColor: "#a0813f" },
                }}
                onClick={() => window.open(event.event_RegLink)}
              >
                Register Now
              </Button>
            </CardActions>
          </Card>
        ))}
      </Box>
    </Box>
  );
  
}
