import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, TextField, Button, Divider, IconButton, InputAdornment } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Visibility, VisibilityOff } from '@mui/icons-material';

// Custom styled TextField component to match the style
const SignupTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputLabel-root": { color: theme.palette.text.primary },
  "& .MuiInputLabel-root.Mui-focused": { color: theme.palette.primary.main },
  "& .MuiOutlinedInput-root": {
    "& fieldset": { borderColor: theme.palette.text.primary },
    "&:hover fieldset": { borderColor: theme.palette.primary.main },
    "&.Mui-focused fieldset": { borderColor: theme.palette.primary.main },
  },
  "& .MuiOutlinedInput-input": { color: theme.palette.text.primary },
}));

const Login = () => {
  const [userEmail, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // To toggle password visibility
  const navigate = useNavigate();

  // If token exists, navigate to Dashboard
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/ShraddhaEvents");
    }
  }, [navigate]);


  //function to handle user Login 
  const handleLogin = async () => {
    if (!userEmail || !password) {
      setMessage("Username and password are required.");
      return;
    }

    setMessage(""); 
    setLoading(true);

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, {
        userEmail,
        password,
      });
      if(data.Sub ==="m") {
        localStorage.setItem("token", data.token);
        navigate("/ShraddhaEvents");
      } 
      else{
        alert("Access Denied ! Please Contact +91 020 41488999")
      }
    
    } catch (error) {
      setMessage(error.response?.data?.message || error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  // Toggle password visibility
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  // Prevent default behavior on mouse down (for the IconButton)
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Handle Enter key press
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin(); // Trigger login when Enter is pressed
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundImage: "url('../../assets/loginbackground.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: 2,
      }}
    >
      <Paper
        elevation={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "380px",
          padding: "40px",
          borderRadius: "10px",
          backgroundColor: "#ebfafc",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          sx={{ color: "#000", marginBottom: "30px" }}
        >
          <img
            alt="logo"
            width="200px"
            height="70px"
            src="../../assets/Shraddha Infinite Logo.png"
          />
        </Typography>

        <Divider style={{ margin: '16px 0' }} />

        <SignupTextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          required
          value={userEmail}
          onChange={(e) => setUsername(e.target.value)}
          onKeyPress={handleKeyPress} // Listen for Enter key press
          disabled={loading}
        />

        <SignupTextField
          label="Password"
          variant="outlined"
          type={showPassword ? "text" : "password"} // Toggle between text and password
          fullWidth
          margin="normal"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={handleKeyPress} // Listen for Enter key press
          disabled={loading}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword} // Toggle password visibility
                  onMouseDown={handleMouseDownPassword} // Prevent default on mouse down
                  edge="end"
                  aria-label="toggle password visibility" // Improved accessibility
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {message && <Typography color="error">{message}</Typography>}
        <Button
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: "#4285F4",
            marginTop: "20px",
            marginBottom: "20px",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#357AE8",
            },
          }}
          onClick={handleLogin}
          disabled={loading}
        >
          {loading ? "Signing in..." : "Sign in"}
        </Button>

        <Divider style={{ margin: '16px 0' }} />

        <Box display="flex" justifyContent="space-between" sx={{ marginTop: "1px" }}>
          <Link to="/forgot-password" style={{ textDecoration: "none" }}>
            <Typography variant="body2" sx={{ color: "#000", fontSize: "13px" }}>
              Forgot Password?
            </Typography>
          </Link>
          <Link to="/signup" style={{ textDecoration: "none" }}>
            <Typography variant="body2" sx={{ color: "#4285F4", fontSize: "15px" }}>
              Register
            </Typography>
          </Link>
        </Box>
      </Paper>
    </Box>
  );
};

export default Login;
