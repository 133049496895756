import React, { useState, useEffect } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
} from "@mui/material";
import axios from "axios";

export default function ShraddhaMemberTV() {
  const token = localStorage.getItem("token");

  const [videoList, setVideoList] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/display/videolist",
        {},
        {
          headers: { Authorization: token },
        }
      );
      const videoLists = response.data.rows;
      setVideoList(videoLists);
      if (videoLists.length > 0) setSelectedVideo(videoLists[0]);
    } catch (error) {
      console.log("Error fetching data");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        flexDirection: { xs: "column", sm: "row" }, // Stack on mobile, row on larger screens
      }}
    >
      {/* Sidebar */}
      <Box
        sx={{
          width: { xs: "100%", sm: "300px" }, // Full width on mobile, fixed width otherwise
          backgroundColor: "#f4f4f4",
          padding: "10px",
          overflowY: "auto",
          mt: "80px",
          ml: "10px",
          boxShadow: { sm: "2px 0 5px rgba(0,0,0,0.1)" }, // Shadow only on larger screens
        }}
      >
        <Typography
          variant="h5"
          sx={{ padding: "10px 0", fontSize: { xs: "1.2rem", sm: "1.5rem" } }} // Smaller font size on mobile
        >
          Training Video 
        </Typography>
        <Divider />
        <List>
          {videoList.map((video, index) => (
            <ListItem
              key={index}
              onClick={() => setSelectedVideo(video)}
              sx={{
                backgroundColor:
                  selectedVideo?.video_Name === video.video_Name
                    ? "#ddd"
                    : "transparent",
                borderRadius: "5px",
                marginBottom: "5px",
              }}
            >
              <ListItemText
                primary={video.video_Name}
                secondary={video.video_Desc}
              />
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Main Content */}
      <Box
        sx={{
          flex: 1,
          padding: { xs: "10px", sm: "20px" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {selectedVideo ? (
          <>
            <Typography
              variant="h4"
              sx={{
                marginBottom: "10px",
                display:"flex",
                justifyContent:"flex-start",
                flexDirection:"row",
                alignItems:"flex-start",
                fontSize: { xs: "1.5rem", sm: "2rem" },
              }}
            >
              {selectedVideo.video_Name}
            </Typography>
            {/* <Typography
              variant="subtitle"
              sx={{
                marginBottom: "20px",
                fontSize: { xs: "1rem", sm: "1.2rem" },
              }}
            >
              {selectedVideo.video_Desc}
            </Typography> */}

            <Box
              sx={{
                position: "relative",
                width: "100%",
                maxHeight: "70vh",
                borderRadius: "10px",
                boxShadow: "0 4px 10px rgba(0,0,0,0.3)",
              }}
            >
              {/* Video Element */}
              <video
                key={selectedVideo.video_Name}
                src={selectedVideo.video_URL}
                controls
                controlsList="nodownload"
                style={{
                  width: "100%",
                  borderRadius: "10px",
                }}
                onContextMenu={(e) => e.preventDefault()} // Block right-click directly on the video
              />
              {/* Invisible Overlay */}
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                  pointerEvents: "none", // Allow video interactions
                }}
                onContextMenu={(e) => e.preventDefault()} // Disable right-click on overlay
              />
            </Box>
          </>
        ) : (
          <Typography
            variant="h6"
            sx={{
              fontSize: { xs: "1rem", sm: "1.2rem" },
              textAlign: "center",
            }}
          >
            No video selected
          </Typography>
        )}
      </Box>
    </Box>
  );
}
