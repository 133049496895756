import React, { useState } from "react";
import {
  TextField,
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function SignUp() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [otpMessage, setOtpMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  // Form validation
  const validateForm = () => {
    const newErrors = {};
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }

    if (!formData.password) {
      newErrors.password = "Password is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const { email: username, password } = formData;

      setIsSubmitting(true);
      try {
        const { status } = await axios.post(
          `${process.env.REACT_APP_API_URL}/shraddha/auth/login`,
          { username, password }
        );

        if (status === 201) {
          setOpenOtpModal(true);
          setOtpMessage(`An OTP has been sent to ${formData.email}. Please check your email.`);
        } else {
          alert("Login failed");
        }
      } catch (error) {
        alert("Invalid credentials or server error");
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleVerifyOtp = async () => {
    const { email: username } = formData;

    if (!otp) {
      setOtpError("OTP is required");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/shraddha/auth/otpVerify`,
        { username, otp }
      );

      if (response.status === 200) {
        alert("OTP verified successfully!");
        localStorage.setItem("shraddhatoken", response.data.token);
        navigate("/ShraddhaAdminUser");
      } else {
        setOtpError("Invalid OTP");
      }
    } catch (error) {
      setOtpError("Invalid OTP.");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
        overflow: "hidden",
      }}
    >
      <Paper sx={{ padding: 7, maxWidth: 400, width: "100%" }}>
        <Typography variant="h3" align="center" sx={{ marginBottom: 3 }}>
          Admin Login
        </Typography>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                name="email"
                value={formData.email}
                onChange={handleChange}
                error={Boolean(errors.email)}
                helperText={errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Password"
                variant="outlined"
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                error={Boolean(errors.password)}
                helperText={errors.password}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  backgroundColor: "#B09246",
                  "&:hover": { backgroundColor: "#946d3f" },
                }}
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Logging in..." : "Login"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <Dialog
        open={openOtpModal}
        onClose={() => setOpenOtpModal(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: "8px",
            padding: "20px",
            height: "50vh", 
            width :"50vh",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          },
        }}
      >

        <Typography variant="body2" color="green" sx={{ marginTop: 2, textAlign: "center" }}>
          {otpMessage}
        </Typography>

        <DialogContent>
          <TextField
            fullWidth
            label="Enter OTP"
            variant="outlined"
            type="text"
            value={otp}
            onChange={handleOtpChange}
            error={Boolean(otpError)}
            helperText={otpError}
            inputProps={{ maxLength: 6 }}
            sx={{
              "& .MuiInputLabel-root": { fontWeight: "500", color: "#3f51b5" },
              "& .MuiOutlinedInput-root": { borderRadius: "8px" },
            }}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={handleVerifyOtp}
            fullWidth
            variant="contained"
            sx={{
              backgroundColor: "#B09246",
              "&:hover": { backgroundColor: "#946d3f" },
              borderRadius: "8px",
            }}
          >
            Verify OTP
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
