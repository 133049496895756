import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { Routes, Route, useLocation } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import MemTopbar from "./components/shraddhaEvents/ShraddhaEventTopBar";
import Dashboard from "./scenes/dashboard";
import Sidebar from "./scenes/global/Sidebar";
import MemberSidebar from "./components/shraddhaEvents/ShraddhaEventSideBar"; 
import AdminSidebar from "./scenes/global/AdminSidebar" 
import AdminTopbar from "./scenes/global/AdminTopbar"
import Team from "./scenes/team";
import TallyCompanies from "./components/Tally-Companies/TallyCompanies";
import PrimaryApprov from "./components/Voucher-Approval/primary-Approval";
import SecondaryApprov from "./components/Voucher-Approval/secondary-Approval";
import FinalApprov from "./components/Voucher-Approval/final-Approval";
import AdminList from "./components/Voucher-Approval/admin-Report";
import ApprovalModuleUsers from "./components/Approval-Module-Users/Approval-Module-Users";
import MarketPlace from "./components/Market_Place/MarketPlace";
import ModuleDetailPage from "./components/Market_Place/ModuleDetailPage";
import ResetPwd from "./authpages/ResetPassword";
import Forgotpassword from "./authpages/Forgotpassword";
import Signup from "./authpages/Signup";
import Confirmation from "./authpages/Confirmation";
import Signupconfirm from "./authpages/Signupconfirm";
import Login from "./authpages/Login";
import PayableReport from "./components/Reports/PayableReport";
import ReceivableReport from "./components/Reports/ReceivableReport";
import Ledger from "./components/TallyData/Ledger";
import VoucherEntry from "./components/Reports/VoucherEntry";
import VoucherCreate from "./components/Reports/VoucherCreate";
import CreditorScore from "./components/Reports/CreditorScore";
import DebitorScore from "./components/Reports/DebitorScore";
import ShraddhaAdminUser from "./components/shraddhaAdmin/shraddhaAdminUser";
import ShraddhaAdmin from "./components/shraddhaAdmin/ShraddhaAdmin"; 
import ShraddhaAdminTraning from "./components/shraddhaAdmin/shraddhaTrainingVideos" 
import ShraddhaAdminEvent from "./components/shraddhaAdmin/sharddhaAdminEvent"
import ShraddhaEvents from "./components/shraddhaEvents/ShraddhaEvents";
import LearningAndEvents from "./components/shraddhaEvents/LearningAndEvents";
import ShraddhaMemeberTV from "./components/shraddhaEvents/ShraddhaMemeberTV";
import ShraddhaInfinite from "./components/shraddhaEvents/ShraddhaInfinite";
import { Navigate } from "react-router-dom";

function App() {
  const [theme, colorMode] = useMode();
  //stores the current url path string
  const location = useLocation();

  const isAuthenticated = localStorage.getItem("token") !== null;

  const PrivateRoute = ({ element, isAuthenticated }) => {
    return isAuthenticated ? element : <Navigate to="/" />;
  };

  //Checks if the url includes the auth route

  const isAuthpage =
    [
      "/",
      "/Signupconfirm",
      "/confirmation",
      "/signup",
      "/forgot-password",
      "/ShraddhaAdmin",
    ].includes(location.pathname) ||
    location.pathname.includes("/reset-password");  

    
  const isAdminPage =
  [
     "/ShraddhaAdminUser", 
     "/ShraddhaAdminTraning", 
     "/ShraddhaAdminEvent"
  ].includes(location.pathname) 

  const isMemberpage = [
    "/ShraddhaEvents",
    "/LearningAndEvents",
    "/ShraddhaMemeberTV",
    "/ShraddhaInfinite", 
  ].includes(location.pathname);

  // const NotFoundPage = () => (
  //   <Box
  //     sx={{
  //       display: "flex",
  //       flexDirection: "column",
  //       justifyContent: "center",
  //       alignItems: "center",
  //       height: "70vh",
  //     }}
  //   >
  //     <Typography variant="h2" color="error">
  //       404
  //     </Typography>
  //     <Typography variant="h5">Page Not Found</Typography>
  //   </Box>
  // );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <main className="content">
            {/* Full-width Topbar */}
            {!isAdminPage && !isMemberpage && !isAuthpage && isAuthenticated && <Topbar />}
            {isMemberpage && <MemTopbar />}  
            {isAdminPage &&<AdminTopbar/> }
            


            {/* Flex container for Sidebar and Content */}
            <div className="layout">
              {/* Sidebar below the Topbar */}
              {!isAdminPage && !isMemberpage && !isAuthpage && isAuthenticated && <Sidebar />}
              {isMemberpage && <MemberSidebar />} 
              {isAdminPage &&<AdminSidebar/> }

              {/* Routes for the application */}
              <div className="route-container">
                <div className="loginRoute">
                  {" "}
                  <Routes>
                    <Route
                      path="/ShraddhaInfinite"
                      element={<ShraddhaInfinite />}
                    />
                    <Route
                      path="/ShraddhaMemeberTV"
                      element={<ShraddhaMemeberTV />}
                    />
                    <Route
                      path="/LearningAndEvents"
                      element={<LearningAndEvents />}
                    />
                    <Route
                      path="/ShraddhaEvents"
                      element={<ShraddhaEvents />}
                    />
                    <Route path="/ShraddhaAdmin" element={<ShraddhaAdmin />} />

                    <Route
                      path="/ShraddhaAdminUser"
                      element={<ShraddhaAdminUser />}
                    />   
                    <Route
                      path="/ShraddhaAdminEvent"
                      element={<ShraddhaAdminEvent/>}
                    />  

                     
                    <Route
                      path="/ShraddhaAdminTraning"
                      element={<ShraddhaAdminTraning />}
                    /> 
                    
                    <Route path="/" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/Signupconfirm" element={<Signupconfirm />} />
                    <Route path="/confirmation" element={<Confirmation />} />
                    <Route
                      path="/forgot-password"
                      element={<Forgotpassword />}
                    />
                    <Route path="/reset-password" element={<ResetPwd />} />
                  </Routes>
                </div>

                <div className="mainRoute">
                  <Routes>
                    {/* <Route
                      path="/Dashboard"
                      element={
                        <PrivateRoute
                          element={<Dashboard />}
                          isAuthenticated={isAuthenticated}
                        />
                      }
                    /> */}
                     {/* <Route path="*" element={< NotFoundPage/>} /> */}
                  
{/* 
                    <Route
                      path="/team"
                      element={
                        <PrivateRoute
                          element={<Team />}
                          isAuthenticated={isAuthenticated}
                        />
                      }
                    /> */}

                    {/* <Route
                      path="/TallyCompanies"
                      element={
                        <PrivateRoute
                          element={<TallyCompanies />}
                          isAuthenticated={isAuthenticated}
                        />
                      }
                    /> */}
                    {/* <Route
                      path="/PrimaryApprov"
                      element={
                        <PrivateRoute
                          element={<PrimaryApprov />}
                          isAuthenticated={isAuthenticated}
                        />
                      }
                    /> */}
                    {/* <Route
                      path="/SecondaryApprov"
                      element={
                        <PrivateRoute
                          element={<SecondaryApprov />}
                          isAuthenticated={isAuthenticated}
                        />
                      }
                    /> */}
                    {/* <Route
                      path="/FinalApprov"
                      element={
                        <PrivateRoute
                          element={<FinalApprov />}
                          isAuthenticated={isAuthenticated}
                        />
                      }
                    /> */}
                    {/* <Route
                      path="/AdminList"
                      element={
                        <PrivateRoute
                          element={<AdminList />}
                          isAuthenticated={isAuthenticated}
                        />
                      }
                    /> */}
                    {/* <Route
                      path="/ApprovalModuleUsers"
                      element={
                        <PrivateRoute
                          element={<ApprovalModuleUsers />}
                          isAuthenticated={isAuthenticated}
                        />
                      }
                    /> */}
                    {/* <Route
                      path="/MarketPlace"
                      element={
                        <PrivateRoute
                          element={<MarketPlace />}
                          isAuthenticated={isAuthenticated}
                        />
                      }
                    /> */}
                    {/* <Route
                      path="/ModuleDetailPage/:id"
                      element={
                        <PrivateRoute
                          element={<ModuleDetailPage />}
                          isAuthenticated={isAuthenticated}
                        />
                      }
                    /> */}
                    {/* ================ tally data ================ */}
                    {/* <Route
                      path="/Ledger"
                      element={
                        <PrivateRoute
                          element={<Ledger />}
                          isAuthenticated={isAuthenticated}
                        />
                      }
                    /> */}

                    {/* ============= reports pages ================= */}
                    {/* <Route
                      path="/VoucherEntry"
                      element={
                        <PrivateRoute
                          element={<VoucherEntry />}
                          isAuthenticated={isAuthenticated}
                        />
                      }
                    /> */}
{/* 
                    <Route
                      path="/VoucherCreate"
                      element={
                        <PrivateRoute
                          element={<VoucherCreate />}
                          isAuthenticated={isAuthenticated}
                        />
                      }
                    /> */}
{/* 
                    <Route
                      path="/ReceivableReport"
                      element={
                        <PrivateRoute
                          element={<ReceivableReport />}
                          isAuthenticated={isAuthenticated}
                        />
                      }
                    /> */}

                    {/* <Route
                      path="/PayableReport"
                      element={
                        <PrivateRoute
                          element={<PayableReport />}
                          isAuthenticated={isAuthenticated}
                        />
                      }
                    /> */}

                    {/* <Route
                      path="/CreditorScore"
                      element={
                        <PrivateRoute
                          element={<CreditorScore />}
                          isAuthenticated={isAuthenticated}
                        />
                      }
                    /> */}
                    {/* <Route
                      path="/DebitorScore"
                      element={
                        <PrivateRoute
                          element={<DebitorScore />}
                          isAuthenticated={isAuthenticated}
                        />
                      }
                    /> */}
                  </Routes>
                </div>
              </div>
            </div>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
