import React from "react";
import { AppBar, Box, Button, Typography, Toolbar } from "@mui/material"; 
import { useNavigate } from "react-router-dom";

const AdminTopbar = () => {
 const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("shraddhatoken");
    navigate("/ShraddhaAdmin");
  };

  return (
    // Top Bar
    <AppBar position="fixed" sx={{ backgroundColor: "#E9F3F5" }}>
      <Toolbar>
        <Typography variant="h3" noWrap sx={{ fontWeight: "bold", color: "#000000" }}>
          ShraddhaAdmin
        </Typography>
        <Button
          sx={{
            marginLeft: "auto",
            fontWeight: "bold",
            fontSize: "14px",
            padding: "6px 16px",
            backgroundColor: "#304251",
            color: "#fff",
          }}
          onClick={handleLogout}
        >
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default AdminTopbar;
