import React from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  Card,
  CardContent,
  Divider,
} from "@mui/material";

const Signupconfirm = () => {

  const regiEmail = localStorage.getItem("regiEmail");

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          // backgroundColor: "#f5f5f5", // Light background 
          backgroundImage: "../../assets/loginbackground.png",
          padding: 2,
        }}
      >
        <Card
          sx={{
            width: "100%",
            textAlign: "center",
            boxShadow: 3,
            borderRadius: 2,
            padding: 3,
          }}
        >
          <CardContent>
            {/* Logo */}
            <img
              src="../../assets/Shraddha Infinite Logo.png"
              alt="Shraddha Infinite"
              style={{
                height: "80px",
                width: "160px",
                marginBottom: "16px",
              }}
            />

            {/* Title */}
            <Typography variant="h2" fontWeight="bold" gutterBottom>
              Email Confirmation
            </Typography>
            <Typography variant="h2" fontWeight="bold" gutterBottom>
              Required
            </Typography>

            {/* Divider */}
            <Divider
              sx={{
                marginY: 2,
                width: "60%",
                mx: "auto",
              }}
            />

            {/* Message */}

            <Typography variant="body1" color="textSecondary" paragraph>
              We sent an email to <strong>{regiEmail}</strong>. Please check
              your inbox to activate your account. If the email is not in your
              inbox, kindly check the Spam folder.
            </Typography>

            {/* Resend Button */}
            <Button
              variant="contained"
              color="primary"
              onClick={() => (window.location.href = "/")}
              sx={{
                marginTop: 2,
                paddingX: 3,
                paddingY: 1,
              }}
            >
              Resend Email
            </Button>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default Signupconfirm;
