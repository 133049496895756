import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  useTheme,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Grid,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";

import Header from "../../components/Header";
import axios from "axios";

export default function ShraddhaAdminEvent() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [UsersData, setUsersData] = useState([]);
  const token = localStorage.getItem("shraddhatoken");
  const [userRow, setuserRow] = useState({});
  const [ShowDialog, setShowDialog] = useState(false);
  const [formValues, setFormValues] = useState({
    member_From: "",
    member_To: "",
    infinite_From: "",
    infinite_To: "",
  });
  const [searchQuery, setSearchQuery] = useState("");  

  const handleDateChange = (field, value) => {
    setFormValues((prev) => ({ ...prev, [field]: value }));
  };

  const handleUpdate = async () => {
    const updateSub = {
      adminId: userRow.id,
      memberFrom: formValues.member_From,
      memberTo: formValues.member_To,
      infiniteFrom: formValues.infinite_From,
      infiniteTo: formValues.infinite_To,
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/shraddha/operation/subUpdate",
        updateSub,
        {
          headers: { Authorization: token },
        }
      );

      if (response) {
        alert(response.data.message);
        setShowDialog(false);
      } 
      // alert("Hellllllllo") 
      // setShowDialog(false);
    } catch (error) {
      alert("Error Update Account", error);
    }
  };

  const LedfetchData = async (userRow) => {
    setuserRow(userRow);
    setFormValues({
      member_From: userRow.member_From || "",
      member_To: userRow.member_To || "",
      infinite_From: userRow.infinite_From || "",
      infinite_To: userRow.infinite_To || "",
    });
    setShowDialog(true);
  };

  const fetchUsersData = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/shraddha/operation/acDisplay",
        {},
        {
          headers: { Authorization: token },
        }
      );

      const users = Object.values(response.data);
      setUsersData(users[0]);
    } catch (error) {
      alert("Error fetching group data", error);
    }
  };

  useEffect(() => {
    fetchUsersData();
  }, []);

  // Filter the users based on search query
  const filteredUsers = UsersData.filter((user) => {
    const searchLower = searchQuery.toLowerCase();
    return (
      user.admin_FirstName.toLowerCase().includes(searchLower) ||
      user.admin_LastName.toLowerCase().includes(searchLower) ||
      user.admin_Email.toLowerCase().includes(searchLower) ||
      user.admin_CmpName.toLowerCase().includes(searchLower) ||
      user.admin_Mobile.toString().includes(searchLower)
    );
  });

  const userListRow = filteredUsers.map((user) => ({
    id: user.admin_Id,
    name: user.admin_FirstName + " " + user.admin_LastName,
    email: user.admin_Email,
    CmpName: user.admin_CmpName,
    mibileNumber: user.admin_Mobile,
    member_From: user.member_From
      ? new Date(user.member_From).toISOString().split("T")[0]
      : "",
    member_To: user.member_To
      ? new Date(user.member_To).toISOString().split("T")[0]
      : "",
    infinite_From: user.infinite_From
      ? new Date(user.infinite_From).toISOString().split("T")[0]
      : "",
    infinite_To: user.infinite_To
      ? new Date(user.infinite_To).toISOString().split("T")[0]
      : "",
  }));

  const columns = [
    {
      field: "id",
      headerName: "User Id",
      headerAlign: "center",
      align: "center",
      width: 80,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "CmpName",
      headerName: "Company Name",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "mibileNumber",
      headerName: "Mobile Number",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "member_From",
      headerName: "Member From",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "member_To",
      headerName: "Member To",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "infinite_From",
      headerName: "Infinite From",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "infinite_To",
      headerName: "Infinite To",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return ( 

          <div>
            <Button
              variant="contained"
              style={{
                backgroundColor: colors.blueAccent[700],
                color: colors.primary[150],
                "&:hover": {
                  backgroundColor: colors.blueAccent[700],
                  color: colors.primary[150],
                },
              }}
              size="small"
              type="button"
              onClick={() => LedfetchData(params.row)}
            >
              Update
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <> 
    

 <Box
        component="main"
        sx={{
          flexGrow: 1,
          paddingTop: 8,
          paddingLeft: 3,
          paddingRight: 3,
          marginLeft: "240px",
          marginTop: 4,
          backgroundColor: "#f4f6f7",
          borderRadius: "10px",
        }}
      >
     
      {/* ================== Show Single Ledger Data ===================== */}
      <Box>
        <Dialog
          open={ShowDialog}
          sx={{
            "& .MuiDialog-paper": {
              backgroundColor: "#f0f0f0",
              padding: 2,
            },
          }}
        >
          <DialogTitle variant="h3" gutterBottom p={0} m={0}>
            Account Details
          </DialogTitle>
          <DialogContent>
            {/* Ledger Information Section */}
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Name"
                  name="Name"
                  size="small"
                  fullWidth
                  value={userRow.name}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Organization"
                  name="Organization"
                  size="small"
                  fullWidth
                  value={userRow.CmpName}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>

            {/* Mailing Details Section */}
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email"
                  name="Email"
                  size="small"
                  fullWidth
                  value={userRow.email}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Mobile No."
                  name="MoNumber"
                  size="small"
                  fullWidth
                  value={userRow.mibileNumber}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>

            {/* Membership Section */}
            <Divider sx={{ marginY: 2 }} />
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Member From"
                  type="date"
                  value={formValues.member_From}
                  onChange={(e) => handleDateChange("member_From", e.target.value)}
                  size="small"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Member To"
                  type="date"
                  value={formValues.member_To}
                  onChange={(e) => handleDateChange("member_To", e.target.value)}
                  size="small"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>

            {/* Infinite Section */}
            <Divider sx={{ marginY: 1 }} />
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Infinite From"
                  type="date"
                  value={formValues.infinite_From}
                  onChange={(e) => handleDateChange("infinite_From", e.target.value)}
                  size="small"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Infinite To"
                  type="date"
                  value={formValues.infinite_To}
                  onChange={(e) => handleDateChange("infinite_To", e.target.value)}
                  size="small"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>

          {/* Centered Action Button */}
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "right",
              padding: 1,
            }}
          >
            <Button
              color="success"
              size="small"
              variant="contained"
              onClick={handleUpdate}
              sx={{
                borderRadius: "10px",
              }}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      {/* ===================================================== */}

      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="All Event " />
          {/* Search Bar */}
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{
              width: "300px",
              marginBottom: "20px",
            }}
          />
        </Box>

        <Box
          height="74vh"
          sx={{
            overflowX: "auto",
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#E9F3F5",
              borderBottom: "none",
              color: colors.primary[120],
              cursor: "default",
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
              boxShadow: "none",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              outline: "none",
              boxShadow: "none",
            },
            "& .MuiDataGrid-columnHeader:focus-visible": {
              outline: "none",
              boxShadow: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: "#E9F3F5",
              color: colors.primary[150],
            },
          }}
        >
          <DataGrid rows={userListRow} columns={columns} disableColumnSort={true} />
        </Box>
      </Box> 
      </Box>
    </>
  );
}
