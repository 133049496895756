import React, { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Divider,
  Grid,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Custom styled TextField component to match the style
const SignupTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputLabel-root": { color: theme.palette.text.primary },
  "& .MuiInputLabel-root.Mui-focused": { color: theme.palette.text.primary },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.text.primary,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: "#357AE8",
    },
  },
  "& .MuiOutlinedInput-input": { color: theme.palette.text.primary },
}));

const validatePassword = (password) => {
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  return (
    password.length >= minLength &&
    hasUpperCase &&
    hasLowerCase &&
    hasSpecialChar
  );
};

const Signup = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [cmpName, setCmpName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [mobile, setUserMobile] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(null);
  const [showPassword, setShowPassword] = useState(false); // Added state to toggle password visibility
  const [termsChecked, setTermsChecked] = useState(false);
  const [error, setError] = useState("");
  const [CheckNo, setCheckNo] = useState("");
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    const valid = validatePassword(password);
    setIsValid(valid);

    if (!valid) {
      return;
    }
    if (
      !userEmail  ||
      !password   ||
      !cmpName    ||
      !firstname  ||
      !lastname   ||
      !mobile
    ) {
      setMessage("All fields are required.");
      return;
    }
    if (!termsChecked) {
      setError("You must agree to the terms and conditions");
      return;
    }

    if (isNaN(mobile) && mobile !== "") {
      setCheckNo("Please enter only numeric characters.");
      return;
    }

  
    setLoading(true);
    setMessage("");

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/auth/registration",
        {
          password,
          userEmail,
          firstname,
          lastname,
          cmpName,
          mobile,
        }
      );

      if (response.data.exists) {
        setMessage(response.data.message);
      } else {
        setMessage(response.data.message);
        localStorage.setItem("regiEmail", userEmail);
        setFirstname("");
        setLastname("");
        setCmpName("");
        setUserEmail("");
        setUserMobile("");
        setPassword("");
        navigate("/Signupconfirm");
      }
    } catch (error) {
      setMessage(
        `Error during registration: ${
          error.response?.data?.message || error.message
        }`
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (event) => {
    setTermsChecked(event.target.checked);
    setError("");
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundImage: "url('../../assets/loginbackground.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: 2,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "380px",
          padding: "40px",
          borderRadius: "10px",
          backgroundColor: "#ebfafc",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          sx={{ color: "#000", marginBottom: "30px" }}
        >
          <img
            alt="logo"
            width="200px"
            height="70px"
            src="../../assets/Shraddha Infinite Logo.png"
          />
        </Typography>

        <Divider style={{ margin: "16px 0" }} />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <SignupTextField
              label="First Name"
              variant="outlined"
              fullWidth
              required
              size="small"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SignupTextField
              label="Last Name"
              variant="outlined"
              fullWidth
              size="small"
              required
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <SignupTextField
              label="Email"
              variant="outlined"
              fullWidth
              size="small"
              required
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <SignupTextField
              label="Mobile No"
              variant="outlined"
              fullWidth
              size="small"
              required
              value={mobile}
              onChange={(e) => setUserMobile(e.target.value)}
              disabled={loading}
              helperText={
                CheckNo ||
                (mobile.length < 10 && mobile.length > 0
                  ? "Mobile number must be at least 10 digits."
                  : "")
              }
              error={
                !!CheckNo || (mobile.length < 10 && mobile.length > 0)
              }
              inputProps={{
                pattern: "[0-9]*", // Only numeric characters allowed
                inputMode: "numeric", // Numeric keyboard on mobile devices
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <SignupTextField
              label="Company"
              variant="outlined"
              fullWidth
              size="small"
              required
              value={cmpName}
              onChange={(e) => setCmpName(e.target.value)}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <SignupTextField
              label="Password"
              variant="outlined"
              type={showPassword ? "text" : "password"} // Toggle between text and password
              fullWidth
              required
              size="small"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={isValid === false}
              helperText={
                isValid === false
                  ? "Password must be at least 8 characters, including uppercase, lowercase, and special characters."
                  : ""
              }
              disabled={loading}
              sx={{
                "& .MuiInputBase-root": {
                  color: isValid === null ? "black" : isValid ? "green" : "red", // Text color
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor:
                      isValid === null ? "black" : isValid ? "green" : "red", // Border color
                  },
                  "&:hover fieldset": {
                    borderColor:
                      isValid === null ? "#357AE8" : isValid ? "green" : "red", // Border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor:
                      isValid === null ? "#357AE8" : isValid ? "green" : "red", // Focused border color
                  },
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsChecked}
                  onChange={handleCheckboxChange}
                  size="small"
                />
              }
              label="Terms and Conditions"
            />
          </Grid>
        </Grid>

        {error && (
          <Grid item xs={12}>
            <Typography color="error">{error}</Typography>
          </Grid>
        )}

        {message && (
          <Typography color="error" align="center" sx={{ marginTop: 2 }}>
            {message}
          </Typography>
        )}

        <Button
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: "#4285F4",
            marginTop: "20px",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#357AE8",
            },
          }}
          onClick={handleRegister}
          disabled={loading}
        >
          {loading ? "Registering..." : "Register"}
        </Button>

        <Divider style={{ margin: "16px 0" }} />

        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ marginTop: "1px" }}
        >
          <Typography variant="body2" sx={{ color: "#000", fontSize: "13px" }}>
            Already have an account?{" "}
            <Link to="/" style={{ textDecoration: "none" }}>
              <Typography
                variant="body2"
                sx={{ color: "blue", fontSize: "13px", display: "inline" }}
              >
                Log in
              </Typography>
            </Link>
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default Signup;
