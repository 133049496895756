import React from "react";
import { Box, Typography } from "@mui/material";

export default function LearningAndEvents() {
  return (
    <Box
      sx={{
        height: "85vh",
        display: "flex",
        justifyContent: "center", 
        alignItems: "center", 
       
      }}
    >
      <iframe
        src="https://shraddhainfosystems.od2.vtiger.com/portal/index.php"
        frameBorder="0"
        width="100%"
        style={{
          maxWidth: "100%", 
          height: "85vh", 
          border: "none", 
          marginTop: "150px", 
        }}
      ></iframe>
    </Box>
  );
}
