import { useState } from "react";
import { Box, Typography, useTheme, List, ListItem, ListItemIcon, ListItemText, Drawer } from "@mui/material";
import { Link } from "react-router-dom";
import { Group as GroupIcon, Event as EventIcon, VideoChat as VideoChatIcon } from "@mui/icons-material";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";


const AdminSidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [selected, setSelected] = useState("Users");

  // This function handles the toggle of the selected section
  const handleSectionClick = (section) => {
    setSelected(section);
  };

  // This function checks if the section is selected
  const isSelected = (section) => selected === section;

  return (
    <Drawer
      sx={{
        width: 240,
        flexShrink: 0,
        position: "fixed",
        "& .MuiDrawer-paper": {
          width: 240,
          boxSizing: "border-box",
          top: 64,
          height: "100vh",
          backgroundColor: "#E9F3F5",
          color: "#000000",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <List>
        {/* Users Section */}
        <Link to="/ShraddhaAdminUser">
          <ListItem
            onClick={() => handleSectionClick("Users")}
            sx={{
              "&:hover": { backgroundColor: "#F7F5EF" },
              backgroundColor: isSelected("Users") ? "#D0E8F5" : "transparent",
              marginBottom: "10px",
            }}
          >
            <ListItemIcon sx={{ color: "#000000" }}>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary="Users" sx={{ color: "#000000" }} />
          </ListItem>
        </Link>

        {/* Events Section */}
        <Link to="/ShraddhaAdminEvent">
          <ListItem
            onClick={() => handleSectionClick("Events")}
            sx={{
              "&:hover": { backgroundColor: "#F7F5EF" },
              backgroundColor: isSelected("Events") ? "#D0E8F5" : "transparent", 
              marginBottom: "10px",
            }}
          >
            <ListItemIcon sx={{ color: "#000000" }}>
              <EventIcon />
            </ListItemIcon>
            <ListItemText primary="Events" sx={{ color: "#000000" }} />
          </ListItem>
        </Link>

        {/* Training Videos Section */}
        <Link to="/ShraddhaAdminTraning">
          <ListItem
            onClick={() => handleSectionClick("Training Videos")}
            sx={{
              "&:hover": { backgroundColor: "#F7F5EF" },
              backgroundColor: isSelected("Training Videos") ? "#D0E8F5" : "transparent", 
              marginBottom: "10px",
            }}
          >
            <ListItemIcon sx={{ color: "#000000" }}>
              <VideoChatIcon />
            </ListItemIcon>
            <ListItemText primary="Training Videos" sx={{ color: "#000000" }} />
          </ListItem>
        </Link>
      </List>
    </Drawer>
  );
};

export default AdminSidebar;
