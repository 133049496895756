import React, { useState } from 'react';
import { Box, Paper, Typography, TextField, Button, Divider } from "@mui/material";
import { styled } from "@mui/material";
import axios from "axios";


// Custom styled TextField component to match the style
const SignupTextField = styled(TextField)({
  "& .MuiInputLabel-root": { color: "#000" },
  "& .MuiInputLabel-root.Mui-focused": { color: "#000" },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#000",
    },
    "&:hover fieldset": {
      borderColor: "#000",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#357AE8",
    },
  },
  "& .MuiOutlinedInput-input": { color: "#000" },
});

const ForggotPwd = () => {
  const [email, setUsername] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);


  const handleFp = async () => {

    setLoading(true);
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL+"/auth/forgot-password", {
        email,
      }); 
      setMessage(response.data.message )
    } catch (error) {
      setMessage(
        `${error.response?.data?.message || error.message}`
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundImage: "url('../../assets/loginbackground.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: 2,
      }}
    >
      <Paper
        elevation={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "380px",
          padding: "40px",
          borderRadius: "10px",
          backgroundColor: "rgba(245, 245, 245, 0.7)",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          sx={{ color: "#000", marginBottom: "0px" }}
        >
          <img
            alt="logo"
            width="200px"
            height="100px"
            src="../../assets/Shraddha Infinite Logo.png"
        
          />
        </Typography>

        <Divider style={{ margin: '16px 0' }} />

        <SignupTextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          required
          value={email}
          onChange={(e) => setUsername(e.target.value)}


        />

        {message && <Typography color="succes">{message}</Typography>}
        <Button
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: "#4285F4",
            marginTop: "20px",
            marginBottom: "20px",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#357AE8",
            },
          }}
          onClick={handleFp}
          disabled={loading}
        >
          Send mail
        </Button>  
        
        <Typography variant="body2" sx={{ fontSize: "15px" }}>
           Password reset instructions will be emailed to you.
            </Typography>
     
      </Paper>
    </Box>
  );
};

export default ForggotPwd;
